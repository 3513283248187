import React from 'react';

function Agent(props){

    var agent = props.agent;
    var color = props.color;

    if(agent.reason !== ''){
        var reason = agent.reason;
    }else{
        reason = agent.currentState
    }

    return(
        <React.Fragment>
            <div className="icon"><i style={{color: color}} className="fas fa-headset"></i></div>
                <div className="data">
                <div className="agent-name">{agent.firstName + ' ' + agent.lastName} ({agent.reporting})</div>
                <div className="agent-timeinqueue">{agent.timeInQueue}</div>
                <div className="agent-reason">{reason}</div>
                <div className="agent-conversation">{agent.queueName ? agent.queueName : sessionStorage.getItem('conv-' + agent.firstName + '-' + agent.lastName)}</div>
            </div>
        </React.Fragment>
    )
}

export default Agent;