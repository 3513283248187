import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as moment from 'moment';
import {getToken, dataSD, getSDMembers, getAgentStates,getAgentConversations} from '../Functions.js';
import Agent from '../components/Agent';
import Header from '../components/Header';
import WallboardBlock from '../components/WallboardBlock';

class WallboardSD extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      currentDate: '',
      SDMembers: '',
      dataSD: '',
      agents: '',
      agentsACD: '',
      agentsIdle: '',
      agentsNonACD: '',
      agentsUnavailable: ''
    }
  }

  getTimeInQueue(datetimeQueue){

    var date_in_queue = moment(datetimeQueue);
    var date_now = moment();

    return moment.utc(moment(date_now,"DD/MM/YYYY HH:mm:ss").diff(moment(date_in_queue,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
  }

  componentDidMount(){

    var membergroupid = this.props.match.params.groupid;
    var agentsgroupid = this.props.match.params.agentsgroupid;

    var members = [];

    if(!sessionStorage.getItem('token') || (sessionStorage.getItem('token') && sessionStorage.getItem('token') === '')){
      getToken();
    }

    setInterval(() => {
      var getDataSD = dataSD(agentsgroupid);
      var $this = this;

      getSDMembers(membergroupid, function(id, data){
        data.forEach(function(member){
          if(!members.includes(member.name)){
            members.push(member.name);
          }
        })
      })

      getAgentStates(agentsgroupid, function(id, data){
        var agentsACD = [];
        var agentsIdle = [];
        var agentsUnavailable = [];
        var agentsNonACD = [];
        if(data){
          data.forEach(function(item){

            var agent = item.firstName + ' ' + item.lastName;

            var TimeInQueue = $this.getTimeInQueue(item.enteredStateOn);
            item.timeInQueue = TimeInQueue;

            if(members.includes(agent)){
              if(item.availableState === 'ACD' || item.availableState === 'Acd' || item.availableState === 'acd'){
                getAgentConversations().then(async data => {
                  data.forEach(function(agent){
                    if(agent.agentFirstName === item.firstName && agent.agentLastName === item.lastName){
                      item.queueName = agent.queueName ? agent.queueName : '';
                      sessionStorage.setItem('conv-' + item.firstName + '-' + item.lastName, agent.queueName);
                    }
                  }) 
                })

                agentsACD.push(item);
              }
              else if(item.availableState === 'Idle'){
                agentsIdle.push(item);
              }
              else if(item.availableState === 'NotAvailable'){
                agentsUnavailable.push(item);
              }
              else if(item.availableState === 'NonACD'){
                agentsNonACD.push(item);
              }
            }
          })
          $this.setState({
            agentsACD: agentsACD,
            agentsIdle: agentsIdle,
            agentsUnavailable: agentsUnavailable,
            agentsNonACD: agentsNonACD
          })
        }
    });

      this.setState({
        dataSD: getDataSD
      })
    }, 5000)
  }

  render(){

    var SDData = this.state.dataSD[0];

    var waitingQueueMax = '';

    if(SDData){
      var getHours = SDData.longestWaitingConversationDuration.split(":")[0];
      var getMinutes = SDData.longestWaitingConversationDuration.split(":")[1];
      var getSeconds = SDData.longestWaitingConversationDuration.split(":")[2];
      waitingQueueMax = getHours + ':' + getMinutes + ':' + getSeconds.toString()[0] + '' + getSeconds.toString()[1];
    }

    if(SDData){
      sessionStorage.setItem('servicelevel', SDData.serviceLevelPercentageToday);
      sessionStorage.setItem('waitingqueue', SDData.waitingConversations);
      sessionStorage.setItem('requeued', SDData.requeuedConversationsToday);
      sessionStorage.setItem('longestwaiting', waitingQueueMax);
      sessionStorage.setItem('offered', SDData.offeredConversationsToday);
      sessionStorage.setItem('handled', SDData.answeredConversationsToday);
      sessionStorage.setItem('abandoned', SDData.abandonedConversationsToday);
    }

    return (
      <div className="App">
        <Header />

        <div className="container-fluid">
          <div className="row">
            <WallboardBlock data={SDData ? SDData.serviceLevelPercentageToday : sessionStorage.getItem('servicelevel')} label="Service Level" />
            <WallboardBlock data={SDData ? SDData.waitingConversations : sessionStorage.getItem('waitingqueue')} label="Contacts waiting" />
            <WallboardBlock data={SDData ? SDData.requeuedConversationsToday : sessionStorage.getItem('requeued')} label="Requeued" />
            <WallboardBlock data={waitingQueueMax ? waitingQueueMax : sessionStorage.getItem('longestwaiting')} label="Longest waiting" />
            <WallboardBlock data={SDData ? SDData.offeredConversationsToday : sessionStorage.getItem('offered')} label="Offered" />
            <WallboardBlock data={SDData ? SDData.answeredConversationsToday : sessionStorage.getItem('handled')} label="Handled" />
            <WallboardBlock data={SDData ? SDData.abandonedConversationsToday : sessionStorage.getItem('abandoned')} label="Abandoned" />
          </div>
          <div className="row agents">
            <div className="col">
              <span className="listname">ACD</span>
              <ul>
              {this.state.agentsACD ? this.state.agentsACD.map((agent, i) => {
                return(
                  <li key={i} className="agent"><Agent agent={agent} color='#ED1C24' /></li>
                );
              }) : ''}
              </ul>
            </div>
            <div className="col">
              <span className="listname">Idle</span>
              <ul>
              {this.state.agentsIdle ? this.state.agentsIdle.map((agent, i) => {
                return(
                  <li key={i} className="agent"><Agent agent={agent} color='#3FAC49' /></li>
                );
              }) : ''}
              </ul>
            </div>
            <div className="col">
              <span className="listname">Non ACD</span>
              <ul>
              {this.state.agentsNonACD ? this.state.agentsNonACD.map((agent, i) => {
                return(
                  <li key={i} className="agent"><Agent agent={agent} color='#0081B3' /></li>
                );
              }) : ''}
              </ul>
            </div>
            <div className="col">
              <span className="listname">Unavailable</span>
              <ul>
              {this.state.agentsUnavailable ? this.state.agentsUnavailable.map((agent, i) => {
                return(
                  <li key={i} className="agent"><Agent agent={agent} color='#F8CA00' /></li>
                );
              }) : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WallboardSD;