import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {getCurrentDate} from './Functions.js';
import Header from './components/Header';

class App extends React.Component{
  render(){

    return (
      <div className="App">
        <Header />
        <div class="container choice-menu" style={{ marginTop: '100px'}}>
        <h1>Geen of onvoldoende parameters</h1>
          <p>Deze wallboard werkt met parameters in de url. Om naar het betreffende dashboard te gaan moet in de url een groepid en een agentgroepid aanwezig zijn (/groepid/agentgroepid)</p>
          <p>De groepid is nodig om de statistieken op te halen van de desbetreffende groep. Hierin wordt o.a. Service level, aantal wachtende, aantal afgehandelde telefoontjes, etc. weergegeven. De agentgroepid is een aparte groepsid. Hiermee kun je live zien welke agents er beschikbaar zijn en welke er bezet of afwezig zijn van de desbetreffende groep..</p>
        </div>
      </div>
    );
  }
}

export default App;
