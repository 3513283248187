import React from 'react';

function WallboardBlock(props){
    return(
        <React.Fragment>
            <div className="col wallboard-block">
                <span className="content">{props.data}</span>
                <span className="label">{props.label}</span>
            </div>
        </React.Fragment>
    )
}

export default WallboardBlock;