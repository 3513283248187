import React, { useState } from 'react';
import { getCurrentDate } from '../Functions';

function Header(){

    var [currentDateTime, setCurrentDateTime] = useState('');

    setInterval(() => {
        var currentDate = getCurrentDate();
        
        var Minutes = new Date().getMinutes();
        var Seconds = new Date().getSeconds();

        if(Minutes === 59 && Seconds === 59){
            sessionStorage.removeItem('token');
            window.location.reload();
        }

        setCurrentDateTime(currentDate);
    }, 1000)

    return(
        <React.Fragment>
            <header className="wallboard-header">
                <div className="datetime-today">{currentDateTime}</div>
                <img src='/sltn-logo.png' className="logo" alt="logo" />
                <div className="department">Servicedesk</div>
            </header>
        </React.Fragment>
    )
}

export default Header;